*,
*::before,
*::after {
	box-sizing: border-box;
	font-family: "Poppins";
}

.container {
	width: 60%;
}

@media screen and (max-width: 600px) {
	.container {
		width: 70%;
	}
}

@media screen and (max-width: 480px) {
	.container {
		width: 90%;
	}
}

@media screen and (max-width: 380px) {
	.container {
		width: 100%;
	}
}
